<template>
  <div>
    <!-- BOCNE MENU -->
    <v-navigation-drawer v-model="drawer" app clipped>
      <!-- Lave bocne menu -->
      <v-list-item link class="px-2">
        <v-list-item-avatar>
          <v-img
            src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=Blonde&facialHairType=BeardLight&facialHairColor=Blonde&clotheType=Hoodie&clotheColor=Black&eyeType=Default&eyebrowType=Default&mouthType=Smile&skinColor=Pale'"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            >{{ this.$store.state.user.firstName }}
            {{ this.$store.state.user.lastName }}
          </v-list-item-title>
          <v-list-item-subtitle
            ><small>{{
              this.$store.state.user.email
            }}</small></v-list-item-subtitle
          >
        </v-list-item-content>
        <v-btn icon to="/personal-setting">
          <v-icon>mdi-account-cog-outline</v-icon>
        </v-btn>
      </v-list-item>

      <!--  ====== SEKCIA AKTUALNYCH INFORMACII A RIADENIA TAXISLUZBY ======  -->
      <v-divider></v-divider>
      <v-list dense shaped>
        <!-- Uvodny Dashboard -->
        <v-list-item
          link
          :to="'/dashboard'"
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleAccountant ||
            roleMaintainer ||
            roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.dashboard") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Dispecerska konzola -->
        <v-list-item
          link
          :to="'/console'"
          @click.stop="drawer = false"
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>mdi-map</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.console") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Dispecerska konzola OSM -->
        <v-list-item
          link
          :to="'/console-osm'"
          @click.stop="drawer = false"
          v-show="roleAdmin || roleSupport ? true : false"
        >
          <v-list-item-action style="width: 20px">
            <v-icon>mdi-map-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.consoleOsm") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- ====== SEKCIA PREVADZKOVYCH UDAJOV ====== -->
        <v-divider></v-divider>
        <v-subheader style="height: 30px">{{
          $t("menu.subheaderOperational")
        }}</v-subheader>
        <!-- Zoznam objednavok -->
        <v-list-item
          link
          :to="'/order'"
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleAccountant //|| roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>mdi-road-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.order") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Zoznam firiem pre firemne jazdy -->
        <v-list-item
          link
          ml-0
          :to="'/account'"
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleAccountant //|| roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>mdi-store</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.account") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Pridelovanie vozidiel vodicom -->
        <v-list-item
          link
          ml-0
          :to="'/driver-vehicle'"
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleAccountant ||
            roleMaintainer ||
            roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>mdi-car-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.driverVehicle")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Zoznam vozidiel a ich vodicov -->
        <v-list-item
          link
          ml-0
          :to="'/vehicle-driver'"
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleAccountant ||
            roleMaintainer ||
            roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>mdi-car-connected</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.vehicleDriver")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Zoznam servisných záznamov -->
        <v-list-item
          link
          ml-0
          :to="'/vehicle-maintenance'"
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleAccountant ||
            roleMaintainer ||
            roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>mdi-car-wrench</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.vehicleMaintenance")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Zoznam adries a editacia polohy adries -->
        <v-list-item
          link
          ml-0
          :to="'/place'"
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>fa-map-pin</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.place") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- SKUPINA REPORTY -->
        <v-list-group
          :value="false"
          prepend-icon="mdi-finance"
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleAccountant //|| roleDispatcher
              ? true
              : false
          "
        >
          <template v-slot:activator>
            <v-list-item-title>{{ $t("menu.reports") }}</v-list-item-title>
          </template>
          <!-- Report s prehladom taxisluzby -->
          <v-list-item link :to="'/report-overview'">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.reportOverview")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-view-dashboard-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-subheader>{{ $t("menu.reportSubheaderStatus") }}</v-subheader>
          <v-list-item link :to="'/report-status-month'">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.reportStatusMonth")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-chart-areaspline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item link :to="'/report-status-day'">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.reportStatusDay")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-chart-areaspline-variant</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item link :to="'/report-status-hour'">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.reportStatusHours")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-chart-line</v-icon>
            </v-list-item-action>
          </v-list-item>
          <!--<v-list-item link :to="'/report-status-month-per-source'">
            <v-list-item-content>
              <v-list-item-title>{{ $t("menu.reportStatusMonthPerSource") }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-chart-bar-stacked</v-icon>
            </v-list-item-action>
          </v-list-item>-->
          <v-list-item
            link
            :to="'/report-daily-vehicles'"
            v-show="roleAdmin || roleSupport ? true : false"
          >
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.reportDailyVehicles")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-chart-line</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-subheader>{{ $t("menu.reportSubheaderSales") }}</v-subheader>
          <v-list-item link :to="'/report-sales-driver'">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.reportSalesDriver")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-cash-multiple</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item link :to="'/report-sales-company'">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.reportSalesCompany")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-store</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-subheader>{{ $t("menu.reportSubheaderWorkshift") }}</v-subheader>
          <!-- reportWorkshift -->
          <v-list-item link :to="'/report-workshift'">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.reportWorkshift")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-calendar-clock</v-icon>
            </v-list-item-action>
          </v-list-item>
          <!-- reportWorkshiftSummary -->
          <v-list-item link :to="'/report-workshift-summary'">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.reportWorkshiftSummary")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-calendar-multiple</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-subheader>{{ $t("menu.reportSubheaderOperations") }}</v-subheader>
          <v-list-item link :to="'/report-call-log'">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.reportCallLog")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-account-details-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item link :to="'/report-order-sent-log'">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.reportOrderSentLog")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-account-details-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-subheader>{{ $t("menu.reportSubheaderUsers") }}</v-subheader>
          <v-list-item link :to="'/report-dispatcher-access-log'">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.reportDispatcherAccessLog")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-account-details-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>

        <!--  ====== SEKCIA POUZIVATELIA A VOZIDLA  ====== -->
        <v-divider
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleAccountant //|| roleDispatcher
              ? true
              : false
          "
        ></v-divider>
        <v-subheader
          style="height: 30px"
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleAccountant //|| roleDispatcher
              ? true
              : false
          "
          >{{ $t("menu.subheaderUsers") }}</v-subheader
        >
        <!-- Zoznam VODICOV -->
        <v-list-item
          link
          :to="'/driver'"
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleAccountant //|| roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>fas fa-user-tie</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.driver") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Zoznam VOZIDIEL -->
        <v-list-item
          link
          :to="'/vehicle'"
          v-show="
            roleAdmin ||
            roleSupport ||
            roleOwner ||
            roleManager ||
            roleAccountant //|| roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>fa-car</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.vehicle") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Zoznam DISPECEROV -->
        <v-list-item
          link
          :to="'/dispatcher'"
          v-show="
            roleAdmin || roleSupport || roleOwner || roleManager //|| roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>fa-user-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.dispatcher") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- ====== SEKCIA NASTAVENI TAXISLUZBY ====== -->
        <v-divider
          v-show="
            roleAdmin || roleSupport || roleOwner || roleManager //|| roleDispatcher
              ? true
              : false
          "
        ></v-divider>
        <v-subheader
          style="height: 30px"
          v-show="
            roleAdmin || roleSupport || roleOwner || roleManager //|| roleDispatcher
              ? true
              : false
          "
          >{{ $t("menu.subheaderSettings") }}</v-subheader
        >
        <!-- Zoznam LOKALIT -->
        <v-list-item
          link
          ml-0
          :to="'/locality'"
          v-show="
            roleAdmin || roleSupport || roleOwner || roleManager //|| roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>mdi-web</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.locality") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Zoznam CENNÍKOV -->
        <v-list-item
          link
          ml-0
          :to="'/pricelist'"
          v-show="
            roleAdmin || roleSupport || roleOwner || roleManager ? true : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>fa-money-bill</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.pricelist") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Zoznam NASTAVENIA -->
        <v-list-item
          link
          ml-0
          :to="'/settings'"
          v-show="
            roleAdmin || roleSupport || roleOwner || roleManager //|| roleDispatcher
              ? true
              : false
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon>fa-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.settings") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Status TAXISLUZBY -->
        <v-list-item
          link
          ml-0
          :to="'/taxiservice-status'"
          :disabled="roleAdmin || roleSupport ? false : true"
          v-show="roleAdmin || roleSupport ? true : false"
        >
          <v-list-item-action style="width: 20px">
            <v-icon>mdi-thermometer-check</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.taxiserviceStatus")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Zoznam TAXISLUZIEB -->
        <v-list-item
          link
          ml-0
          :to="'/taxiservice'"
          :disabled="roleAdmin || roleSupport ? false : true"
          v-show="roleAdmin || roleSupport ? true : false"
        >
          <v-list-item-action style="width: 20px">
            <v-icon>fas fa-city</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.taxiservice") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- ====== SEKCIA INYCH INFORMACII, SPRAVY A NASTAVENI SYSTEMU ====== -->
        <v-divider></v-divider>
        <v-subheader style="height: 30px">{{
          $t("menu.subheaderOther")
        }}</v-subheader>
        <!-- Zoznam planovanych funkcionalit - ROADMAP -->
        <v-list-item link :to="'/roadmap'">
          <v-list-item-action style="width: 20px">
            <v-icon color="info">mdi-chart-gantt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title style="color: #2196f3">{{
              $t("menu.roadmap")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Nastavenia obchodného modelu - SERVICE PLAN -->
        <v-list-item
          link
          :to="'/service-plan'"
          v-show="roleAdmin || roleSupport ? true : false"
        >
          <v-list-item-action style="width: 20px">
            <v-icon>mdi-wallet-plus</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.servicePlan") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Zoznam SYSTEMOVYCH INFORMACII -->
        <v-list-group
          :value="false"
          prepend-icon="mdi-server"
          :disabled="roleAdmin || roleSupport ? false : true"
          v-show="roleAdmin || roleSupport ? true : false"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ $t("menu.system") }}</v-list-item-title>
          </template>
          <!-- Zoznam Oznamenia -->
          <v-list-item
            link
            :to="'/message-dispatch-list'"
            v-show="roleAdmin || roleSupport ? true : false"
          >
            <v-list-item-content>
              <v-list-item-title>{{
                $t("menu.messageDispatch")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-bell-cog-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <!-- Zoznam Feedback -->
          <v-list-item
            link
            :to="'/feedback-list'"
            v-show="roleAdmin || roleSupport ? true : false"
          >
            <v-list-item-content>
              <v-list-item-title>Feedback</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action style="width: 20px">
              <v-icon small>mdi-comment-quote-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>

        <v-divider class="my-1"></v-divider>

        <v-list-item @click.stop="showLogoutDialog = true">
          <v-list-item-action style="width: 20px">
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.signout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-1"></v-divider>

        <v-list-item
          link
          @click="
            releaseVersion == 'beta' ||
            releaseVersion == 'alpha' ||
            releaseVersion == 'local'
              ? (showProdDialog = true)
              : (showBetaDialog = true)
          "
          :color="
            releaseVersion == 'beta'
              ? 'secondary'
              : 'alpha'
              ? 'red'
              : 'local'
              ? 'purple'
              : 'orange'
          "
        >
          <v-list-item-action style="width: 20px">
            <v-icon
              :color="
                releaseVersion == 'beta'
                  ? 'blue'
                  : 'alpha'
                  ? 'red'
                  : 'local'
                  ? 'purple'
                  : 'orange'
              "
              >{{
                releaseVersion == "beta" ||
                releaseVersion == "alpha" ||
                releaseVersion == "local"
                  ? "mdi-flask-remove-outline"
                  : "mdi-flask-outline"
              }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              releaseVersion == "beta" ||
              releaseVersion == "alpha" ||
              releaseVersion == "local"
                ? $t("menu.versionProd")
                : $t("menu.versionBeta")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="'/about'">
          <v-list-item-action style="width: 20px">
            <v-icon>fa-info-circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.about") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="mb-6 pb-6"></v-divider>
    </v-navigation-drawer>

    <!-- HLAVICKA -->
    <v-app-bar
      app
      clipped-left
      color="primary"
      v-if="!['console', 'console-osm'].includes($route.name)"
    >
      <!-- Hlavicka aplikacie -->
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <!-- <v-icon>fas fa-bars</v-icon> -->
        <v-icon>{{ drawer ? "fas fa-chevron-left" : "fas fa-bars" }}</v-icon>
      </v-app-bar-nav-icon>
      <v-badge
        :value="!$vuetify.breakpoint.smAndUp"
        overlap
        v-if="
          releaseVersion && releaseVersion != '' && releaseVersion != 'prod'
        "
        :content="
          releaseVersion == 'local'
            ? 'L'
            : releaseVersion == 'alpha'
            ? 'A'
            : releaseVersion == 'beta'
            ? 'B'
            : ''
        "
        :color="
          releaseVersion == 'local'
            ? 'red'
            : releaseVersion == 'alpha'
            ? 'purple'
            : releaseVersion == 'beta'
            ? 'secondary'
            : 'primary'
        "
      >
      </v-badge>
      <template v-if="$vuetify.breakpoint.smAndUp">
        <!-- Zobraznenie loga podla tmavej temy a skrytie loga na malych displejoch -->
        <img
          v-if="this.$vuetify.theme.dark"
          :src="require('../assets/taxximo_logo_white.svg')"
          height="40"
        />
        <img
          v-else
          :src="require('../assets/taxximo_logo_black.svg')"
          height="40"
        />
        <!--<v-toolbar-title>Taxximo</v-toolbar-title>-->
        <!-- zobrazit maly text o aku verziu (releaseVersion) sa jedna, napriklad beta -->
        <v-chip
          v-if="
            releaseVersion && releaseVersion != '' && releaseVersion != 'prod'
          "
          :color="
            releaseVersion == 'local'
              ? 'red'
              : releaseVersion == 'alpha'
              ? 'purple'
              : releaseVersion == 'beta'
              ? 'secondary'
              : 'primary'
          "
          text-color="white"
          class="ml-2"
          small
          >{{ releaseVersion }}</v-chip
        >
      </template>
      <v-spacer></v-spacer>
      <v-select
        class="mr-4"
        :items="taxiPickerData.available"
        v-model="taxiPickerData.selected"
        dense
        style="max-width: 200px"
        hide-details
        filled
        @change="onTaxiPickerChange()"
        outlined
        :prepend-icon="
          $vuetify.breakpoint.mdAndUp ? 'mdi-home-city' : undefined
        "
        :disabled="roleAdmin || roleSupport ? false : true"
        :loading="loadingDataForPicker"
      >
        <template v-slot:no-data>
          <v-btn right small color="error" @click="getTaxiserviceForPicker()">{{
            $t("btnReload")
          }}</v-btn>
        </template>

        <template slot="selection" slot-scope="{ item }">
          {{ item.name }}
        </template>

        <template slot="item" slot-scope="{ item, attrs, on }">
          <v-list-item v-on="on" v-bind="attrs">
            <v-list-item-icon>
              <v-icon
                v-if="item.status == 'ACTIVE'"
                :color="
                  item.id === taxiPickerData.selected.id ? 'warning' : 'success'
                "
                >mdi-home-city</v-icon
              >
              <v-icon
                v-else-if="item.status == 'BLOCKED'"
                :color="
                  item.id === taxiPickerData.selected.id ? 'warning' : 'error'
                "
                >mdi-cancel</v-icon
              >
              <v-icon
                v-else-if="item.status == 'ARCHIVED'"
                :color="
                  item.id === taxiPickerData.selected.id ? 'warning' : 'grey'
                "
                >mdi-archive-cancel</v-icon
              >
              <v-icon
                v-else
                :color="
                  item.id === taxiPickerData.selected.id ? 'warning' : 'grey'
                "
                >mdi-home-city-outline</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  item.id === taxiPickerData.selected.id
                    ? 'font-weight-bold'
                    : ''
                "
                >{{ item.name }}
                {{ item.id === taxiPickerData.selected.id ? " * " : "" }}
              </v-list-item-title>
              <v-list-item-subtitle
                :class="
                  item.id === taxiPickerData.selected.id
                    ? 'font-weight-bold'
                    : ''
                "
                >{{ item.city }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
      <!-- Prepinanie tmavej temy -->
      <v-btn icon @click="isDark = !isDark">
        <v-icon v-if="!isDark">fa-moon</v-icon>
        <v-icon v-if="isDark">fa-sun</v-icon>
      </v-btn>
      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <!-- Vyber jazyka -->
          <v-btn icon v-on="on">
            <gb-flag :code="getLocale()" size="small" />
          </v-btn>
        </template>
        <v-list flat>
          <v-list-item @click="changeLocale('sk')">
            <v-list-item-icon>
              <gb-flag code="sk" size="small" />
            </v-list-item-icon>
            <v-list-item-title
              ><small>{{ $t("Main.language.SK") }}</small></v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="changeLocale('en')">
            <v-list-item-icon>
              <gb-flag code="us" size="small" />
            </v-list-item-icon>
            <v-list-item-title
              ><small>{{ $t("Main.language.EN") }}</small></v-list-item-title
            >
          </v-list-item>
          <!--<v-list-item @click="changeLocale('cs')">
            <v-list-item-icon>
              <gb-flag code="cz" size="small" />
            </v-list-item-icon>
            <v-list-item-title
              ><small>{{ $t("Main.language.CS") }}</small></v-list-item-title
            >
          </v-list-item>-->
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- Na podstranke CONSOLE zobrazit namiesto hlavicky aplikacie iba tlacidlo na otvorenie laveho bocneho menu -->
    <div
      style="position: fixed; bottom: 5px; z-index: 100"
      v-if="['console', 'console-osm'].includes($route.name)"
    >
      <v-card class="pa-1 ma-1">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer">
          <!-- <v-icon>fas fa-bars</v-icon> -->
          <v-icon :class="{ 'red--text': drawer }">{{
            drawer ? "fas fa-chevron-left" : "fas fa-bars"
          }}</v-icon>
        </v-app-bar-nav-icon>
      </v-card>
    </div>

    <v-container
      fluid
      :class="['console', 'console-osm'].includes($route.name) ? 'pa-0' : ''"
    >
      <router-view></router-view>
    </v-container>

    <!-- Odhlasovanie dispecera -->
    <v-dialog
      v-model="showLogoutDialog"
      transition="dialog-top-transition"
      max-width="440"
    >
      <v-card>
        <v-toolbar color="secondary" dark flat>
          <v-toolbar-title>{{
            $t("Main.showLogoutDialog.title")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-logout</v-icon>
        </v-toolbar>
        <v-card-text class="pt-4">{{
          $t("Main.showLogoutDialog.text1")
        }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="success" @click="showLogoutDialog = false">
            {{ $t("btnClose") }}
          </v-btn>

          <!-- <v-btn text color="error" @click.stop="signout()">
            {{ $t("Main.showLogoutDialog.signoutButton") }}
          </v-btn> -->

          <v-btn text color="info" @click.stop="logout()">
            {{ $t("Main.showLogoutDialog.logoutButton") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Prepnutie na beta verziu -->
    <v-dialog
      v-model="showBetaDialog"
      transition="dialog-top-transition"
      max-width="440"
    >
      <v-card>
        <v-toolbar color="secondary" dark flat>
          <v-toolbar-title>{{
            $t("Main.showBetaDialog.title")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-flask-outline</v-icon>
        </v-toolbar>
        <v-card-text class="pt-4"
          ><b>{{ $t("Main.showBetaDialog.text1") }}</b> <br />
          <br />
          {{ $t("Main.showBetaDialog.text2") }}<br />
          <br />
          {{ $t("Main.showBetaDialog.text3") }}<br />
          {{ $t("Main.showBetaDialog.text4") }}<br />
          <br />
          {{ $t("Main.showBetaDialog.text5") }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="success" @click="showBetaDialog = false">
            {{ $t("btnClose") }}
          </v-btn>

          <v-btn text color="error" @click.stop="gotoVersion()">
            {{ $t("Main.showBetaDialog.switchButton") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Prepnutie na produkcnu verziu -->
    <v-dialog
      v-model="showProdDialog"
      transition="dialog-top-transition"
      max-width="440"
    >
      <v-card>
        <v-toolbar color="secondary" dark flat>
          <v-toolbar-title>{{
            $t("Main.showProdDialog.title")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-flask-remove-outline</v-icon>
        </v-toolbar>
        <v-card-text class="pt-4"
          >{{ $t("Main.showProdDialog.text1") }}<br />
          <br />
          {{ $t("Main.showProdDialog.text2") }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="success" @click="showProdDialog = false">
            {{ $t("btnClose") }}
          </v-btn>

          <v-btn text color="error" @click.stop="gotoVersion()">
            {{ $t("Main.showProdDialog.switchButton") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
import { Role } from "../enums/RoleEnum";

export default {
  name: "app",
  props: {
    source: String,
  },
  data: () => ({
    // langs: [
    //   { name: "Slovensky", title: "SK", short: "sk" },
    //   { name: "English", title: "EN", short: "us" },
    // ],
    Role,
    drawer: true,
    isDark: false,

    loadingDataForPicker: false,
    taxiPickerData: {},

    showLogoutDialog: false,

    showBetaDialog: false,
    showProdDialog: false,
    releaseVersion: "",

    //Taxiservice id je dolezite zatial aby sme iba zablokovanli niektorej taxisluzbe niektore menu polozky
    taxiserviceId: null,

    //Roles
    roleAdmin: false,
    roleSupport: false,
    roleOwner: false,
    roleManager: false,
    roleAccountant: false,
    roleMaintainer: false,
    roleDispatcher: false,

    snackbar: {
      show: false,
      message: "OK",
      color: "success",
      timeout: 2000,
    },
  }),
  watch: {
    isDark: function (val) {
      this.$vuetify.theme.dark = val;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    // "taxiPickerData.selected"(newVal) {
    //   console.log("cccccccccc "+this.taxiPickerData.selected.id)
    //   //to work with changes in "myArray"
    // },
  },
  methods: {
    changeLocale(locale) {
      this.$store.commit("setAppLocale", locale);
      this.$i18n.locale = locale;
      // this.$router.go()  //refreshne aktualnu page
    },

    getLocale() {
      var locale = this.$store.state.appLocale;
      if (locale == "en") {
        locale = "us";
      } else if (locale == "cs") {
        locale = "cz";
      }
      return locale;
    },
    getVersion() {
      var version = window.location.href;
      //get domain name of 3th level (after 2 slashes and before fist "." or "/")
      version = version.substring(version.indexOf("//") + 2);
      if (version.indexOf(".") > -1) {
        version = version.substring(0, version.indexOf("."));
      } else if (version.indexOf("/") > -1) {
        version = version.substring(0, version.indexOf("/"));
      }
      //if version string begins with localhost, then set version to "local"
      if (version.substring(0, 9) == "localhost") {
        version = "local";
      }
      if (version.substring(0, 3) == "127") {
        version = "local";
      }
      if (
        version == "alpha" ||
        version == "alfa" ||
        version == "glampoll" ||
        version == "vue"
      ) {
        version = "alpha";
      }
      if (version == "beta") {
        version = "beta";
      }
      //console.log("version: " + version);
      //pri produkcnej verzii nic nezobrazovat
      if (
        version == "dis" ||
        version == "dispatch" ||
        version == "dispatching"
      ) {
        version = "";
      }
      this.releaseVersion = version;
    },

    gotoVersion() {
      if (
        this.releaseVersion == "beta" ||
        this.releaseVersion == "alpha" ||
        this.releaseVersion == "local"
      ) {
        window.open("https://dis.taxximo.com", "_self");
      } else {
        window.open("https://beta.taxximo.com", "_self");
      }
    },

    onTaxiPickerChange() {
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/dispatcher/assign-me-taxi/` +
            this.taxiPickerData.selected.id
        )
        .then((response) => {
          //this.$router.go()
          //zapis ID taxisluzby, aby sme tento udaj mohli pouzivat napriklad pri vyhladavani adresy pre konkretne taxisluzby
          localStorage.setItem(
            "selectedTaxiserviceId",
            this.taxiPickerData.selected.id
          );
          //nacitat povolene moduly pre vybranu taxisluzbu
          this.getModuleSettings();
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("Main.msgChangeTaxiserviceSuccess"); //"Taxislužba bola zmená. Aktualizujte si načítané údaje.";
          this.snackbar.color = "success";
          //nacitat zoznam taxisluzieb pre taxiPicker, kebyze bola pridana medzicasom nova taxisluzba (zatial workaround)
          this.getTaxiserviceForPicker();
          //vymazat cookie s polohou hlavneho stanovista, kedze sa zmenila taxislužba
          this.eraseCookie("defaultStandPosition");
        })
        .catch((e) => {
          console.log(e);
          //this.$router.go()
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("Main.msgChangeTaxiserviceError"); //"Nepodarilo sa zmeniť taxislužbu...";
          this.snackbar.color = "error";
        });
    },

    getInfoFromBe() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/dispatcher/user/info`
        )
        .then((response) => {
          this.loading = false;

          this.$store.commit("setUser", response.data);
          //this.$router.push({ name: "console" }); // Pri prihlaseni zobrazit priamo stranku CONSOLE
          this.$router.push({ name: "dashboard" }); // Pri prihlaseni zobrazit priamo stranku DASHBOARD
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("setUser", null);
          firebase
            .auth()
            .signOut()
            .then(() => {
              //this.$store.commit("setUser", null);
              this.$router.push({ path: "/login" }); // login
            });
        });
    },

    getTaxiserviceForPicker() {
      this.loadingDataForPicker = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/taxiservice/picker-available`
        )
        .then((response) => {
          this.loadingDataForPicker = false;
          var taxiPickerData = response.data;
          //order by status asc, name asc
          taxiPickerData.available.sort(function (a, b) {
            if (a.status < b.status) {
              return -1;
            }
            if (a.status > b.status) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          this.taxiPickerData = taxiPickerData;
          this.loadingDataForPicker = false;
          //zapis ID taxisluzby, aby sme tento udaj mohli pouzivat napriklad pri vyhladavani adresy pre konkretne taxisluzby
          localStorage.setItem(
            "selectedTaxiserviceId",
            this.taxiPickerData.selected.id
          );
        })
        .catch((e) => {
          console.log(e);
          this.loadingDataForPicker = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t(
            "Main.msgGetTaxiservicListForPickerError"
          );
          this.snackbar.color = "error";
        });
    },

    // get module settings from /dispatching/taxiservice/module
    getModuleSettings() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/taxiservice/module`
        )
        .then((response) => {
          this.$store.commit("setModule", response.data);
          //console.log("main-module settings", response.data);
        })
        .catch((e) => {
          console.log(e);
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetModulesError");
          this.snackbar.color = "error";
        });
    },
    signout() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/xxxxxxx`)
        .then((response) => {
          this.logout();
        })
        .catch((e) => {
          console.log(e);
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("Main.msgSignOutError");
          this.snackbar.color = "error";
        });
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit("setUser", null);
          this.$store.commit("setModule", null);
          this.$router.push({ path: "/login" }); // login
        });
    },
    //Vymazanie cookie
    eraseCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      document.cookie =
        nameC + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    /*markLoadedGoogleMapsApi() {
      this.$emit("googleMapsLoaded");
      console.log("Main.vue - markLoadedGoogleMapsApi - google maps loaded");
    },*/
    /*loadGoogleMapsApi() {
      const apiKey = "AIzaSyAdG-Vz3YTYE4F5MTALr6-rU1XbQhD-9Ig";
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=markLoadedGoogleMapsApi`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);*/
    //this.$emit("googleMapsLoaded");
    //console.log("Main.vue - load - google maps loaded");

    //Nacitanie Google Maps API s klucom zo servera ak nie je nacitane
    /*try {
        const response = await axios.get('https://your-backend-server.com/api_key');
        const apiKey = response.data.api_key;
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=markLoadedGoogleMapsApi`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        this.$emit("googleMapsLoaded");

        script.addEventListener("load", () => {
          //this.initMap();
        });
      } catch (error) {
        console.error("Error fetching API key:", error);
      }*/
    //},
  },
  created() {
    this.getVersion();
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
  },
  mounted() {
    //Mostik medzi plain javascript funkciou a vue metodov
    //window.markLoadedGoogleMapsApi = this.markLoadedGoogleMapsApi;

    //Nacitanie Google Maps API ak nie je nacitane, skontrolovat ci je nacitane podla src (iba podla casti stringu "https://maps.googleapis.com/maps/api/js", lebo ostatne sa moze menit)
    /*if (
      !document.querySelector(
        "script[src*='https://maps.googleapis.com/maps/api/js']"
      )
    ) {
      this.loadGoogleMapsApi();
      console.log("Main.vue - google maps api loaded");
    }*/
    //Zablokovanie položiek menu podľa rolí
    let userRoles = this.$store.state.user.roleList;
    this.roleAdmin = userRoles.includes("ADMIN"); //Role.ADMIN
    this.roleSupport = userRoles.includes("SUPPORT");
    this.roleOwner = userRoles.includes("OWNER");
    this.roleManager = userRoles.includes("MANAGER");
    this.roleAccountant = userRoles.includes("ACCOUNTANT");
    this.roleMaintainer = userRoles.includes("MAINTAINER");
    this.roleDispatcher = userRoles.includes("DISPATCHER");
    //console.log("roleAdmin: " + this.roleAdmin);
    //console.log("roleSupport: " + this.roleSupport);
    //console.log("roleOwner: " + this.roleOwner);
    //if (this.roleAdmin || this.roleSupport) {
    this.getTaxiserviceForPicker();
    //}
    //Nacitanie ulozenej temy
    const theme = localStorage.getItem("dark_theme");
    //const bodyElement = document.getElementsByTagName("body")[0];
    if (theme) {
      if (theme == "true") {
        this.isDark = true;
        this.$vuetify.theme.dark = true;
        //bodyElement.classList = "darkScrollbar";
      } else {
        this.isDark = false;
        this.$vuetify.theme.dark = false;
        //bodyElement.classList = "";
      }
    } else {
      //if nothig set (no cookie) evaluate if night or day
      //check if is night time and set dark theme
      var now = new Date();
      var hours = now.getHours();
      if (hours >= 21 || hours <= 5) {
        this.isDark = true;
        this.$vuetify.theme.dark = true;
      } else {
        this.isDark = false;
        this.$vuetify.theme.dark = false;
      }
    }
  },
  computed: {},
};
</script>
<!-- <style scoped src="../assets/darkscrollbar.css"></style> -->